<!-- 知名学者 -->
<template>
  <div class="renowned-scholars">
    <!-- 检索表 -->
    <div class="retrieval">
      <div class="decorate"> <span>按姓氏首字母检索：</span></div>

      <div class="letters" @click="handlerLetters($event)">
        <span
          v-for="(item, index) in uppercaseLetters"
          :key="index"
          :class="clickDom == item ? 'color-#6E6E6E!' : ''"
        >
          {{ item }}
        </span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="user-list">
      <div class="sort-order"><span>按姓氏首字母排序：</span></div>

      <ul class="list" v-if="data.userList.length > 0">
        <li v-for="item in data.userList" :key="item" @click="handlerTo(item.jump_url)">
          <div class="top">
            <img :src="$img + item.thumb" alt="" />
          </div>
          <div class="bottom">
            <div>
              <h2>{{ item.title_en }}</h2>
              <div class="position">
                <span class="equally">{{ item.author_en }}</span>
              </div>
            </div>
            <div class="describe">
              <span>{{ item.description_en }}</span>
            </div>
          </div>
        </li>
      </ul>
      <el-empty description="暂无内容" v-else />
    </div>
  </div>
  <!-- 分页 -->
  <div class="paging" v-show="data.userList.length > 0">
    <el-pagination
      background
      layout="pager"
      :page-size="data.limit"
      :total="data.total"
      @current-change="handlerChange"
    />
  </div>
</template>

<script setup>
  // import contentTitle from '@/layout/component/contentTitle.vue'
  import { onMounted, ref, reactive } from 'vue'
  import { apiNewsList } from '@/api/list.js'
  import useParams from '@/hooks/getParams.js'
  // 获取url中的参数
  let params = useParams()
  const handlerTo = (url) => {
   window.open(url,'_blank')
  }

  let data = reactive({
    page: 1,
    limit: 9,
    total: 0, // 总条数
    userList: [], // 新闻数据列表
    search_py: ''
  })
  // 生成26个字母
  let uppercaseLetters = ref(['#'])
  const createLetter = function () {
    for (let i = 65; i <= 90; i++) {
      uppercaseLetters.value.push(String.fromCharCode(i))
    }
  }
  // 获取人员列表
  const userList = async () => {
    let { code, data: res } = await apiNewsList({
      limit: data.limit,
      page: data.page,
      search_py: data.search_py,
      type_id: params.detailsId,
      is_en:3
    })
    if (code == 200) {
      data.userList = res.data
      data.total = res.total
    }
  }
  onMounted(() => {
    createLetter()
    userList()
  })
  // 分页
  const handlerChange = (num) => {
    data.page = num
    userList()
  }

  // 点击字母表检索
  let clickDom = ref('#')
  const handlerLetters = (e) => {
    let dom = e.target
    clickDom.value = dom.innerHTML
    data.search_py = dom.innerHTML == '#' ? '' : dom.innerHTML
    userList()
  }
</script>

<style lang="scss" scoped>
  @import '@/style/paging.scss';

  .renowned-scholars {
    width: 100%;
    .retrieval {
      width: 100%;
      height: 1.8281rem;
      background: url(~@/assets/edp/images/layout/lookup.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      padding-top: .6719rem;
      padding-left: .4531rem;

      .decorate {
        height: .2109rem;
        display: flex;
        align-items: center;
        span {
          font-size: .1875rem;
          font-weight: bold;
          color: #6e6e6e;
          margin-left: .1094rem;
          line-height: .1875rem;
        }
        &::before {
          content: '';
          display: inline-block;
          width: .0547rem;
          height: .2109rem;
          background: #6e6e6e;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }

      .letters {
        margin-top: .2344rem;
        width: 7.9453rem;
        height: .2188rem;
        font-size: .2188rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;

        > span {
          color: #a6291a;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .user-list {
      .sort-order {
        height: .2813rem;
        font-size: .1875rem;
        font-weight: bold;
        color: #6e6e6e;
        margin-top: .5rem;
        margin-bottom: .3125rem;
      }
      .list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 2.7188rem);
        grid-row-gap: .3125rem; /*网格每行之间间隔20px*/
        justify-content: space-between;
        > li {
          width: 2.7188rem;
          height: 4.3594rem;
          // background: #dedede;
          background: linear-gradient(#dedede, #dedede);
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          // margin-bottom: 40px;
          transition: background 0.3s;

          > .top {
            width: 100%;
            height: 2.8047rem;

            > img {
              width: 2.4375rem;
              height: 100%;
            }
          }

          > .bottom {
            width: 100%;
            flex: 1;
            padding: .1875rem .2813rem .3047rem .2813rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h2 {
              width: 2.1563rem;
              height: .2422rem;
              font-size: .1875rem;
              font-weight: 600;
              color: #333333;
              line-height: .1719rem;
            }

            .position {
              display: flex;
              align-items: center;
              margin-top: .0781rem;

              .equally {
                height: .1875rem;
                font-size: .1406rem;
                font-weight: 400;
                color: #333333;
                line-height: .2031rem;
              }
              .division {
                display: block;
                width: .0078rem;
                height: .1016rem;
                background: #7e7e7e;
                border-radius: 0px 0px 0px 0px;
                opacity: 0.3;
              }
            }

            .describe {
              width: 2.1563rem;
              height: .3516rem;
              font-size: .0938rem;
              font-weight: 400;
              color: #7e7e7e;
              line-height: .1875rem;
              @include ellipsis;
            }
          }

          &:hover {
            background: linear-gradient(196deg, #ffbda5 0%, #de3a00 100%);
            span,
            h2 {
              color: #fff !important;
            }
          }
        }

        & li:nth-child(3n) {
          margin-right: 0px;
        }

        & li:nth-last-child(-n + 3) {
          margin-bottom: 0px;
        }
      }
    }
  }
</style>
